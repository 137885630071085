import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPages = ({ data }) => {
  console.log('data', data)
  return (
    <Layout>
      <Container>
        <SEO title="blog" />
        blog list
      </Container>
    </Layout>
  )
}

export default BlogPages

const Container = styled.div`
  font-size: 100px;
  color: ${({ theme }) => theme.colors.default};
`

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            slug
          }
        }
      }
    }
  }
`
